import React from 'react'
import { Link } from "react-router-dom"
import logo from '../img/logo_nerdxdigital.webp'

const Nav = () => {
    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container">

                <Link to="/">
                    <div className="logo">
                        <img src={logo} alt="logo de NerdX Digital" />
                    </div>
                </Link>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="icon-bar"><i className="fas fa-bars"></i></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Link to="/expertise">
                                <div className="nav-link">
                                    Ce que nous faisons
                                </div>
                            </Link>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link" href='' onClick={(e) => { e.preventDefault(); window.location='/#nosréalisations' } } >
                                Nos Réalisations
                            </a>
                        </li>
                        <li className="nav-item">
                            <Link to="/about">
                                <div className="nav-link">
                                    Qui sommes nous
                                </div>
                            </Link>
                        </li>
                        
                        <li className="nav-item">
                            <a className="nav-link butn bord curve" href="mailto:contact@nerdxdigital.com">Contactez-nous</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Nav