import React from 'react'
import Nav from '../component/nav'
import Header from '../component/header'
import Footer from '../component/footer'
import About from '../component/about'
import Work from '../component/work'
import Expertise from '../component/expertise'
import '../css/plugins.css'
import '../css/style.css'


const RdvLayout = () => {
    return (
        <div>
            <Nav />
            
                <div class="calendly-inline-widget" data-url="https://calendly.com/fabienamoussou20062001?hide_landing_page_details=1&hide_gdpr_banner=1" style="min-width:320px;height:700px;"></div>
            
            <Footer />
        </div>
    )
}

export default RdvLayout