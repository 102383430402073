import React from 'react'
import Nav from '../component/nav'
import Footer from '../component/footer'
import '../css/plugins.css'
import '../css/style.css'
import img from '../img/NerdX_Digital_why.webp'
import pattern from '../img/patrn.svg'

const AboutLayout = () => {
    return (
        <div>
            <Nav />
            <header className="works-header fixed-slider valign sub-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7 col-md-9 static">
                            <div className="capt mt-50">
                                <div className="parlx text-center">
                                    <h2 className="color-font">Nous sommes</h2>
                                    <h1 className="color-font">NerdX Digital</h1>
                                    <h6>"Chez nous à NerdX Digital, nous essayons toujours d'aller au-delà de notre génie afin d'apporter une valeur ajoutée à tout ce que l'on touche."</h6>
                                    <p>Nous sommes donc convaincus que la technologie peut être utilisée pour avoir un impact positif sur l'environnement tout en répondant aux besoins des entreprises</p>
                                    <h6>"Digitaliser le monde sans polluer c'est possible"</h6>
                                </div>

                                <div className="bactxt custom-font valign">
                                    <span className="full-width">Web Eco</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section className="intro-section pt-50 pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-4">
                            <div className="htit sm-mb30">
                                <h3>QUI SOMMES NOUS ?</h3>
                            </div>
                        </div>
                        <div className="col-lg-8 offset-lg-1 col-md-8">
                            <div className="text">
                                <p className="wow txt mb-10" data-splitting>Nous sommes la première agence web éco-responsable en Afrique spécialisée dans la transformation digitale, la conception digitale éco-responsable, 
                                    c'est-à-dire la création de solutions numériques (sites web, applications web, IA, IoT, plateformes web, etc.) qui respectent l'environnement. </p>
                                <p className="wow txt" data-splitting>Nous sommes une équipe jeunes passionnés de la technologie et de l'environnement, 
                                    qui mettons notre génie, notre créativité et nos compétences au service de votre transformation digitale éco-responsable. Nous travaillons avec des entreprises, 
                                    des organisations et des entrepreneures pour les aider à digitaliser leur activité 
                                    tout en réduisant leur empreinte carbone en ligne, améliorant leur image et leur impact sur le web.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="services bords lficon section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9 col-md-10">
                            <div className="sec-head  text-center">
                                <h6 className="wow fadeIn" data-wow-delay=".5s">Nos services</h6>
                                <h3 className="wow color-font">Nous sommes la meilleure solution pour votre transformation digitale.</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 wow fadeInLeft" data-wow-delay=".5s">
                            <div className="item-box">
                                <div>
                                    <span className="icon pe-7s-phone"></span>
                                </div>
                                <div className="cont">
                                    <h6>Eco-Conception Web & Mobile</h6>
                                    <p>Nos solutions web sont conçues pour être plus économique, plus performant, optimiser la consommation énergétique, réduire les émissions de carbone et favoriser une merveilleuse expérience utilisateur.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInLeft" data-wow-delay=".7s">
                            <div className="item-box">
                                <div>
                                    <span className="icon pe-7s-cloud-download"></span>
                                </div>
                                <div className="cont">
                                    <h6>Hébergement Eco éco-responsable</h6>
                                    <p>Nous proposons un service de maintenance et d’hébergement de site web et application web sur des serveurs éco responsable appelé green hosting. Ainsi que des services cloud et SaaS respectueux de l'environnement.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInLeft" data-wow-delay=".9s">
                            <div className="item-box">
                                <div>
                                    <span className="icon pe-7s-display1"></span>
                                </div>
                                <div className="cont">
                                    <h6>Mesure et audit carbone</h6>
                                    <p>Que ce soit dans le cadre d'une création de site, d'une application ou d'une refonte, nous mesurons et analysons les réductions obtenues d'émission de Co2, de consommation d'eau et consommation énergétique.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInLeft" data-wow-delay=".5s">
                            <div className="item-box">
                                <div>
                                    <span className="icon pe-7s-diskette"></span>
                                </div>
                                <div className="cont">
                                    <h6>Formation & Accompagnement & Sensibilisation</h6>
                                    <p>L'éco-conception commence dès la première génération d'idées, ainsi nous aidons tout au long du projet. Notre mission est aussi clarifier à travers des sensibilisations l'impact environnemental du numérique.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="min-area sub-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="img">
                                <img className="thumparallax-down" src={img} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 valign">
                            <div className="content pt-0">
                                <h4 className="wow color-font">Notre Identité</h4>
                                <p className="wow txt" data-splitting>Chez NerdX Digital, 
                                    nous sommes fiers de travailler avec des partenaires qui partagent notre vision d'un avenir plus vert et plus responsable, d'un numérique plus inclusif et éco-responsable.
                                </p>
                                <ul className="feat">
                                    <li className="wow fadeInUp" data-wow-delay=".2s">
                                        <h6><span>1</span> NOTRE VISION</h6>
                                        <p><strong className="text-bold">Digitaliser l'Afrique, le monde sans polluer.</strong> <br/>(Contribuer efficacement à la lutte contre le changement climatique en Afrique.)</p>
                                    </li>
                                    <li className="wow fadeInUp" data-wow-delay=".4s">
                                        <h6><span>2</span> Notre Mission</h6>
                                        <p>Concevoir et développer des solutions numériques bas-carbones et écologiques. Mais surtout créer des solutions innovantes contre le dérèglement climatique. Nous vous accompagnons dans votre parcours de transformation digitale en mettant l'accent sur la durabilité et la responsabilité environnementale.</p>
                                    </li>
                                    <li className="wow fadeInUp" data-wow-delay=".6s">
                                        <h6><span>3</span>Pourquoi Nous</h6>
                                        <p>Nous proposons des solutions web qui allient performance digitale et responsabilité environnementale. 
                                            De la conception de sites web éco-conçus à l'optimisation de la consommation énergétique de vos plateformes en ligne, 
                                            nous vous aidons à réduire votre empreinte carbone tout en maximisant votre présence en ligne.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="call-action section-padding bg-img" data-background={pattern}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-lg-9">
                            <div className="content sm-mb30">
                                <h6 className="wow" data-splitting>Parlons-en</h6>
                                <h2 className="wow" data-splitting>maintenant de<br/> <b className="back-color">votre projet</b>.</h2>
                            </div>
                        </div>

                        <div className="col-md-4 col-lg-3 valign">
                            <a href="mailto:contact@nerdxdigital.com" target='_blank' rel='noreferrer' className="butn bord curve wow fadeInUp" data-wow-delay=".5s"><span>Prenez un rendez-vous</span></a>
                        </div>

                    </div>
                </div>
            </section>
            
            <Footer />
        </div>
    )
}

export default AboutLayout